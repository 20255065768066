
import Api from 'src/utils/apiClient';
export const getSportData = async (state) => {
    try {
        // console.log("state.auth", state);
        const respond = await Api({
            method: 'GET',
            url: 'sports/all',
            user: state.auth
        });
        if (respond && respond.data) {
            // if (state.company && state.company.sportHideKey && state.company.sportHideKey !== 'EXOS')
            //     return respond.data.filter((s) => s.enableKey !== "EXOS");
            return respond.data
        }
        else {
            return [];
        }
    } catch (error) {
        console.log("error in Get Sports, Error: ", error.toString());
        return [];
    }

}
export const getAccessTokenData = async (member) => {
    try {
        const respond = await Api({
            method: 'GET',
            url: `loginattemts/?memberId=${member.memberId}`,
            user: member
        });
        if (respond && respond.data) {
            return respond.data.attemts === 1;
        }
        else {
            return false;
        }
    } catch (error) {
        console.log("error in Get Sports, Error: ", error.toString());
        return false;
    }

}
export const getCompanyData = async (auth, id) => {
    try {
        const res = await Api({
            method: 'GET',
            url: `companyId/${id}`,
            user: auth
        });
        return res;
    } catch (error) {
        console.log("error in company get by Id", error.toString());
        return false;
    }

} 
export const clearUserCache = async (auth, uid) => {
    try {
        const res = await Api({
            method: 'GET',
            url: `user/cache/clean/${uid}`,
            user: auth
        });
        return res;
    } catch (error) {
        console.log("error in", error.toString());
        return false;
    }

} 