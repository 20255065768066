/* eslint-disable consistent-return */
/* eslint-disable no-multi-assign */
const preventActions = (document) => {
  try {
    const isLocal = String(window.location.href).includes('localhost');

    if (isLocal) return;

    if (['development', 'local'].includes(process.env.REACT_APP_ENV)) {
      return;
    }

    console.log = console.warn = console.error = () => { };

    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });

    document.onkeydown = (e) => {
      if (e.keyCode === 123) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) {
        return false;
      }
    };
  } catch (error) {
    console.error(error);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { preventActions };
