import moment from 'moment';

const initState = (() => {
  return {
    route: {
      isCoach: false
    },
    auth: { id: null, memberId: null, member: {} },
    dashboard: {
      isLoading: true,
      registered: 0,
      registeredByZone: 0,
      validZoneinAccount: 0,
      freeTrial: 0,
      subscriotionIos: {
        allRegisterd: 0,
        oneMonth: 0,
        sixMonth: 0,
        oneYear: 0,
        valid: 0,
        subscriptionContinueIosAccount: 0
      },
      subscriotionAndroid: {
        allRegisterd: 0,
        oneMonth: 0,
        threeMonth: 0,
        oneYear: 0,
        valid: 0
      },
      cancelled: {
        oneMonth: 0,
        threeMonth: 0,
        oneYear: 0
      },
      validAccount: 0,
      cancelAfterPayment: 0,
      resultByMonth: [],
      resultByType: {
        ios: { data: [] },
        android: { data: [] },
        zonein: { data: [] }
      }
    },
    users: [],
    company: {},
    activityLevels: [],
    sports: [],
    coupons: [],
    nutrition: [],
    selectedUsers: [],
    orders: {
      filterDate: new Date(),
      list: []
    },
    recommendedNutritionStats: {
      mealCard: [],
      meals: [],
      mealsAndExercises: [],
      exercises: [],
      stats: {
        totalConsumedCalories: 0,
        totalConsumedCarbohydrates: 0,
        totalConsumedProteins: 0,
        totalRecomendedProtein: 0,
        totalRecommendedCalories: 0,
        totalRecommendedCarbs: 0
      }
    },
    report: {
      mealLogs: [],
      exerciseLogs: [],
      hydrationLogs: [],
      loginHistoryLogs: [],
      goalsLogs: [],
      ordersLogs: [],
      messagesLogs: [],
      mealAssetsLogs: []
    },
    userNutritionState: {
      nutritionDay: moment(),
      graphMain: 0,
      now: 0,
      loggedWaterCount: 0
    },
    userCustomCalories: {},
    mealRepeatInfo: {},
    cart: {
      meals: [],
      mealCard: {}
    },
    todayOrders: [],
    memberGroups: [],
    selectedGroup: {},
    restaurants: [],
    customRecipesFoodsNIngredientsNutritionXFav: {}
  };
})();

export { initState };
