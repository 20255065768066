import axios from 'axios';
import Config from '../config';

export default async ({
  method, url, headers, data, user, token, timeout
}) => {
  let baseUrl = `${Config.BASE_URL}${url}`;

  if (token) {
    baseUrl = `${baseUrl}?access_token=${token}`;
  }

  if (user) {
    baseUrl = baseUrl.includes('?') ? `${baseUrl}&access_token=${user.id}` : `${baseUrl}/?access_token=${user.id}`;
  }

  try {
    // uncomment if you want trace web api calls...
    // console.trace(`web call on ${url}`);
    return await axios({
      method: method || 'get',
      url: baseUrl,
      ...timeout ? { timeout } : {},
      headers: {
        'Content-Type': 'application/json',
        'x-from': 'web',
        ...headers
      },
      data
    });
  } catch (error) {
    if (error.response && error.response.data.code === 'UNAUTHORIZED') {
      localStorage.clear();
      window.location.reload();
    } if (error.response && error.response.data.code === 'ZONEIN_PAYMENT_FAILD') {
      localStorage.clear();
      window.location.replace('/welcome?showSubscriptionWarning=true');
    } else {
      return error.response;
    }
  }
};
