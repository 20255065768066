import React, { useState, useEffect, useMemo, Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import { datadogRum } from '@datadog/browser-rum';
import theme from 'src/theme';
import * as serviceWorker from './serviceWorker';
// import App from './App';
import { AppContext, initState } from './context';
import Api from './utils/apiClient';
import LoadingPage from './components/LoadingPage';
import { preventActions } from './utils/keyboard';
import { getCompanyData, getSportData } from './utils/commonAPI';

datadogRum.init({
  applicationId: '62e885e4-48c0-4f6b-ad2c-3501bee93a97',
  clientToken: 'pubb472de2ec5c5acc707d9aff229848bdc',
  site: 'datadoghq.com',
  service:'zonein-web',
  env:'prod',
  // Specify a version number to identify the deployed version of your application in Datadog 
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel:'allow'
});
    

const App = lazy(() => import('./App'));

function Provider() {
  const [state, setState] = useState(initState);
  const providerValue = useMemo(() => ({
    state,
    setState: ((updates) => {
      setState((prevState) => ({ ...prevState, ...updates }));
    })
  }), [state, setState]);
  const [isLoading, setLoading] = useState(false);
  const location = useLocation();


  const validateToken = async (token) => {
    try {
      const profileData = await Api({
        method: 'GET',
        url: 'profile',
        token
      });

      let company = {};
      if (profileData.data.member
        && profileData.data.member.parent
        && profileData.data.member.parent.company) {
        const response = await getCompanyData(profileData.data, profileData.data.member.parent.company);
        company = response.data;
      }

      const [ activityLevelsResponse, sports ] = await Promise.all([
        Api({
          method: 'GET',
          url: 'activity-levels',
          user: profileData.data
        }),
        getSportData(state)
      ]); 

      setState({
        ...state,
        ...{ auth: profileData.data, activityLevels: activityLevelsResponse.data },
        company,
        sports
      });

      setLoading(false);
    } catch (error) {
      localStorage.clear();
      // alert('Session expired! Please log in again.');
    }
  };

  // Prevent User Actions
  preventActions(window.document);

  useEffect(() => {
    setLoading(true);
    if ('localStorage' in window) {
      const token = localStorage.getItem('TOKEN');
      if (token) {
        if (location.pathname !== '/resetpasswordinfo') { validateToken(token); }
      } else {
        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    datadogRum.startSessionReplayRecording();
  }, []);

  return (
    <AppContext.Provider value={providerValue}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Suspense fallback={<LoadingPage />}>
          {isLoading ? <LoadingPage /> : <App />}
        </Suspense>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

ReactDOM.render((
  <BrowserRouter>
    <Provider />
  </BrowserRouter>
), document.getElementById('root'));

serviceWorker.unregister();
