import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    background: {
      dark: '#F4F6F8',
      primary: '#0abde3',
      default: colors.common.white,
      paper: colors.common.white,
      black: '#000000'
    },
    primary: {
      main: '#0abde3',
      gray: '#dddddd',
      light: '#7ed6df',
      button: '#757575',
      black: '#000000'
    },
    error: {
      main: '#e74c3c'
    },
    secondary: {
      main: '#9b59b6'
    },
    selected: {
      main: '#8bd69b'
    },
    text: {
      white: colors.white,
      gray: colors.gray,
      dark: colors.black,
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    },
  },
  shadows,
  typography,
  colors,
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1920
    }
  },
  overrides: {
    MuiCardHeader: {
      action: {
        marginTop: 0,
        marginRight: 0
      }
    }
  }
});

export default theme;
