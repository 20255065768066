const environment = {
  localProd: {
    BASE_URL: 'http://localhost:3000/api/',
    stripeKey: 'pk_test_Ahm2YYhyuOzlRWvOpJ3Y5QHr00CjC0ZoSw',
    v: '3.21.26',
    devText: process.env.REACT_APP_ENV,
    translationAPIKey: 'AIzaSyDlPY0I60J8VrAv9pNKjqpiAiKTgil11rs',
    firebaseConfig: {
      apiKey: 'AIzaSyAKYu6GrtSwf7-oMgfScUPhwWFajutoIWQ',
      authDomain: 'zonein-prod.firebaseapp.com',
      databaseURL: 'https://zonein-prod.firebaseio.com',
      projectId: 'zonein-prod',
      storageBucket: 'zonein-prod.appspot.com',
      messagingSenderId: '243067157708',
      appId: '1:243067157708:web:85665348e546c366039669',
      webPushKeyPair:
        'BLdqcy3x8zjmVtuIZk_l7FgXsM92eiP_1WReMqhJPYheR175BFcFzct4dWDyt8BB5riHmEj-0HHAbVd99jmhHdY'
    },
    calendly: {
      clientID: 'ALWDWarKhQZo7h1f-xZdy9AKlJJKwft0m55k8-3079Q',
      clientSecret: 'M7MbbWr8SNh5c8zc4QXgw7i7ULJjQ7mgNKYsf2zsan4',
      webhookSigningKey: 'iCy1xdWbXSyFoiPyG1UBUlUSO14pFJrW9kK1h7d9kjg'
    }
  },
  local: {
    BASE_URL: 'http://localhost:3000/api/',
    stripeKey: 'pk_test_Ahm2YYhyuOzlRWvOpJ3Y5QHr00CjC0ZoSw',
    v: '3.21.26',
    devText: process.env.REACT_APP_ENV,
    translationAPIKey: 'AIzaSyDlPY0I60J8VrAv9pNKjqpiAiKTgil11rs',
    firebaseConfig: {
      apiKey: 'AIzaSyDtDMohX9xzgjlnZwLLoXn2oGju6az4awU',
      authDomain: 'zonein-d0870.firebaseapp.com',
      databaseURL: 'https://zonein-d0870.firebaseio.com',
      projectId: 'zonein-d0870',
      storageBucket: 'zonein-d0870.appspot.com',
      messagingSenderId: '494199434231',
      appId: '1:494199434231:web:272b3ded02a9e1028a5cf2'
    },
    calendly: {
      clientID: 'ALWDWarKhQZo7h1f-xZdy9AKlJJKwft0m55k8-3079Q',
      clientSecret: 'M7MbbWr8SNh5c8zc4QXgw7i7ULJjQ7mgNKYsf2zsan4',
      webhookSigningKey: 'iCy1xdWbXSyFoiPyG1UBUlUSO14pFJrW9kK1h7d9kjg'
    },
    webPushKeyPair:
      'BLdqcy3x8zjmVtuIZk_l7FgXsM92eiP_1WReMqhJPYheR175BFcFzct4dWDyt8BB5riHmEj-0HHAbVd99jmhHdY'
  },
  development: {
    BASE_URL: 'https://dev-api.zoneinpro.com/api/',
    stripeKey: 'pk_test_Ahm2YYhyuOzlRWvOpJ3Y5QHr00CjC0ZoSw',
    devText: process.env.REACT_APP_ENV,
    translationAPIKey: 'AIzaSyDlPY0I60J8VrAv9pNKjqpiAiKTgil11rs',
    v: '3.21.26',
    firebaseConfig: {
      apiKey: 'AIzaSyDtDMohX9xzgjlnZwLLoXn2oGju6az4awU',
      authDomain: 'zonein-d0870.firebaseapp.com',
      databaseURL: 'https://zonein-d0870.firebaseio.com',
      projectId: 'zonein-d0870',
      storageBucket: 'zonein-d0870.appspot.com',
      messagingSenderId: '494199434231',
      appId: '1:494199434231:web:272b3ded02a9e1028a5cf2'
    },
    calendly: {
      clientID: 'ALWDWarKhQZo7h1f-xZdy9AKlJJKwft0m55k8-3079Q',
      clientSecret: 'M7MbbWr8SNh5c8zc4QXgw7i7ULJjQ7mgNKYsf2zsan4',
      webhookSigningKey: 'iCy1xdWbXSyFoiPyG1UBUlUSO14pFJrW9kK1h7d9kjg'
    },
    webPushKeyPair:
      'BLdqcy3x8zjmVtuIZk_l7FgXsM92eiP_1WReMqhJPYheR175BFcFzct4dWDyt8BB5riHmEj-0HHAbVd99jmhHdY'
  },
  production: {
    BASE_URL: 'https://api.zoneinpro.com/api/',
    devText: 'Zonein Dashboard',
    translationAPIKey: 'AIzaSyDlPY0I60J8VrAv9pNKjqpiAiKTgil11rs',
    stripeKey: 'pk_live_eLXU7CrjGZ31lDKSt2K2E2lZ00kR0MVPcK',
    v: '3.21.26',
    firebaseConfig: {
      apiKey: 'AIzaSyAKYu6GrtSwf7-oMgfScUPhwWFajutoIWQ',
      authDomain: 'zonein-prod.firebaseapp.com',
      databaseURL: 'https://zonein-prod.firebaseio.com',
      projectId: 'zonein-prod',
      storageBucket: 'zonein-prod.appspot.com',
      messagingSenderId: '243067157708',
      appId: '1:243067157708:web:85665348e546c366039669',
      webPushKeyPair:
        'BLdqcy3x8zjmVtuIZk_l7FgXsM92eiP_1WReMqhJPYheR175BFcFzct4dWDyt8BB5riHmEj-0HHAbVd99jmhHdY'
    },
    calendly: {
      clientID: 'ALWDWarKhQZo7h1f-xZdy9AKlJJKwft0m55k8-3079Q',
      clientSecret: 'M7MbbWr8SNh5c8zc4QXgw7i7ULJjQ7mgNKYsf2zsan4',
      webhookSigningKey: 'iCy1xdWbXSyFoiPyG1UBUlUSO14pFJrW9kK1h7d9kjg'
    }
  }
};

export default environment[process.env.REACT_APP_ENV];
