import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core';
import logo from '../assets/images/logoadvance.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
    backgroundImage: 'linear-gradient(to right top, #051937, #004b72, #0083a8, #00c0d2, #41ffed)',
  },
}));

export default function CircularIndeterminate({ title, noLoading }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {title ? (
        <Typography style={{
          color: '#ffffff',
          fontSize: 18,
          fontWeight: '700'
        }}
        >
          {title}
        </Typography>
      ) : <img src={logo} style={{ width: 150, height: 150 }} />}
      {!noLoading && <CircularProgress style={{ color: '#ffffff' }} size={20} />}
    </div>
  );
}
